.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  word-wrap: break-word;      /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap;      /* current browsers */
}

.App-link {
  color: #61dafb;
}

table {
  font-size: 20px;
}

.price {
  background-color: #8d9b9b; 
  color: #000000;
  border-radius: 25px;
  text-align: center;
  width: fit-content;
  padding: 5px;
}

tr:nth-child(even) {
  background-color: #8d9b9b;
  color: #282c34;
}

tr:nth-child(odd) {
  background-color: rgb(179, 179, 179);
  color: #282c34;
}

td {
  padding: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
